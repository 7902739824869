import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import CssBaseline from '@mui/material/CssBaseline'
import { createTheme, responsiveFontSizes, ThemeProvider } from '@mui/material'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

const theme = responsiveFontSizes(
  createTheme({
    palette: {
      primary: {
        // blue
        main: '#2196f3'
      },
      secondary: {
        // gray
        main: '#9e9e9e'
      },
      mode: 'dark'
    }
  })
)

root.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <App />
        </ThemeProvider>
    </React.StrictMode>
)
