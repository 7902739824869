import React, { type ReactElement } from 'react'
import './App.css'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Unstable_Grid2'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import Stack from '@mui/material/Stack'
import CardContent from '@mui/material/CardContent'
import Paper from '@mui/material/Paper'

function App (): ReactElement {
  return (
        <Container maxWidth={false} disableGutters sx={{
          height: '100vh',
          display: 'flex',
          flexDirection: 'column'
        }}>
            <Stack spacing={2} p={2}>
                <Typography variant='h1' align={'center'}>
                    QuickNimbus
                </Typography>
                <Typography variant='h5' color='secondary' align={'center'}>
                    Cutting edge software emphasizing on affordability and
                    accessibility.
                </Typography>
                <About />
            </Stack>
            <Footer />
        </Container>
  )
}

function About (): ReactElement {
  return (
        <Grid container spacing={2}>
            <Grid xs={12} md={4}>
                <Card sx={{ height: '100%' }}>
                    <CardHeader title='Vision' titleTypographyProps={{
                      align: 'center', textTransform: 'uppercase'
                    }} />
                    <CardContent>
                        <Typography variant='body1'>
                            We wish to develop the missing piece of the puzzle
                            in connecting various cloud providers such as AWS,
                            Azure, and GCP.
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
            <Grid xs={12} md={4}>
                <Card sx={{ height: '100%' }}>
                    <CardHeader title='Mission' titleTypographyProps={{
                      align: 'center', textTransform: 'uppercase'
                    }} />
                    <CardContent>
                        <Typography variant='body1'>
                            We aim to provide a unified interface for
                            interacting with cloud providers.
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
            <Grid xs={12} md={4}>
                <Card sx={{ height: '100%' }}>
                    <CardHeader title='Values' titleTypographyProps={{
                      align: 'center', textTransform: 'uppercase'
                    }} />
                    <CardContent>
                        <Typography variant='body1'>
                            We value affordability and accessibility.
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
  )
}

function Footer (): ReactElement {
  return (
        <Paper sx={{
          p: 2,
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: 'primary.main',
          color: 'primary.contrastText'
        }}>
            <Typography variant='body1'>
                &copy; 2023 QuickNimbus
            </Typography>
        </Paper>
  )
}

export default App
